import React, {Fragment, useEffect} from 'react';
import Head from 'next/head';
import PropTypes from 'prop-types';
import {useRouter} from 'next/router';
import {pageview} from "../../../public/scripts/gtm";
import theme from '../../../.storybook/theme';

const HtmlHead = ({metatags}) => {
  // @see:
  // https://github.com/vercel/next.js/tree/canary/examples/with-google-tag-manager
  const router = useRouter();
  useEffect(() => {
    router.events.on('routeChangeComplete', pageview);
    return () => {
      router.events.off('routeChangeComplete', pageview);
    }
  }, [router.events]);

  return (
      <Fragment key="head-fragment">
        {/*<Head key="head">*/}
        {/*  /!*<meta name="viewport" content={`width=device-width, minimum-scale=1, initial-scale=1`} key="viewport"/>*!/*/}
        {/*  /!*<meta httpEquiv="X-UA-Compatible" content="IE=edge" key="xua"/>*!/*/}
        {/*  /!*<meta name="theme-color" content={theme.palette.primary.main}/>*!/*/}
        {/*  /!*<link rel="icon" href="/favicon.ico"/>*!/*/}
        {/*  /!*<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>*!/*/}
        {/*  /!*<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>*!/*/}
        {/*  /!*<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>*!/*/}
        {/*  /!*<link rel="manifest" href="/site.webmanifest"/>*!/*/}
        {/*  /!*<link rel="mask-icon" href="/safari-pinned-tab.svg" color="#00245d"/>*!/*/}
        {/*  /!*<link rel="shortcut icon" href="/favicon.ico" type="image/vnd.microsoft.icon" key="shortcut"/>*!/*/}
        {/*</Head>*/}
        {metatags.map((tag, i) => {
          let type = tag[0]['#tag'];
          return (
              <Head key={`head${i}`}>
                {type == 'link' && <link rel={tag[0]['#attributes'].rel}
                                         href={tag[0]['#attributes'].href}
                                         key={tag[1]}></link>}
                {type == 'meta' && tag[1] == 'title' &&
                <title key="title">{tag[0]['#attributes'].content}</title>
                }
                {type == 'meta' && tag[1] != 'title' &&
                <meta name={tag[0]['#attributes']?.property || tag[1]}
                      content={tag[0]['#attributes'].content}
                      key={tag[1]}/>
                }
              </Head>
          );
        })}
      </Fragment>
  );
};

HtmlHead.propTypes = {
  metatags: PropTypes.arrayOf(PropTypes.array),
};
HtmlHead.defaultProps = {
  metatags: [],
};

export default HtmlHead;
