import React, {useState, MouseEvent} from 'react';
import Image from "next/image";
import Popover from '@material-ui/core/Popover';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import WebformEmbed from '../../01_atoms/WebformEmbed';

const useStyles = makeStyles((theme) => ({
  agentPopupContent: {
    width: 400,
    '& .MuiPopover-paper': {
      width: '400px !important'
    }
  },
  heading: {
    lineHeight: 1.2,
    marginBottom: 0,
    fontSize: 20
  },
  realtor: {
    lineHeight: 1.2,
    marginBottom: 0,
    fontSize: 18
  },
  details: {
    fontSize: 18,
    marginBottom: 0,
    lineHeight: 1
  }
}));
const AGENT_STORAGE_VAR = 'gmuser';

export default function AgentPopup(props) {
  // const [selected, setSelected] = useState(false);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  let personal_agent = false;
  if (typeof window !== "undefined") {
    personal_agent = localStorage.getItem(AGENT_STORAGE_VAR) !== null
        ? JSON.parse(localStorage.getItem(AGENT_STORAGE_VAR))
        : false;
  }


  let pers_agent_img = null;
  let pers_agent_descr = null;

  if (personal_agent) {
    if (personal_agent.imageOrig) {
      pers_agent_img = (
          <Grid item xs={3}>
            <Image style={{layout: 'fixed'}}
                   src={personal_agent.imageOrig}
                   alt={personal_agent.imgAlt || `Agent ${personal_agent.gmuser_name}`}
                   width={80}
                   height={80}/>
          </Grid>
      );
    }
    else if (!personal_agent.imageOrig) {
      pers_agent_img = (
          <Grid item xs={3}>
            <Box bgcolor="grey.100" px={3} py={5}></Box>
          </Grid>
      );
    }

    pers_agent_descr = (
        <Grid item xs={9}>
          <Box>
            <Typography className={classes.realtor} align="center"
                        component="h6">
              <strong>{personal_agent.gmuser_name}</strong>
            </Typography>
            {personal_agent.type &&
            <p className={classes.details}
               align="center">{personal_agent.subtitle}</p>
            }
            <p className={classes.details} align="center">
              {personal_agent.gmuser_phone}
              {personal_agent.gmuser_email &&
              <span>
                          <br/>
                          <a href={`mailto:${personal_agent.gmuser_email}`}
                             title={`Send agent an email`}
                             className="mail">{personal_agent.gmuser_email}</a>
                        </span>
              }
            </p>
          </Box>
        </Grid>
    );
  }
  else if (!personal_agent) {
    pers_agent_descr = (
        <Grid item xs={12}>
          <Box>
            <Typography className={classes.heading} align="center"
                        component="h4">
              <strong>CONTACT&nbsp;</strong>Eaton Realty
            </Typography>
            <p align="center" className={classes.details}
               style={{margin: ".5rem 0"}}>
              <a href="mailto:info@eatonrealty.com"
                 title={`Send Eaton Realty an email`}
                 className="mail">info@eatonrealty.com</a>
            </p>
            <p className={classes.details} align="center"
               style={{margin: ".5rem 0 0"}}>
              <a href="tel:18136728022" className="phone"
                 title={`Call Eaton Realty`}>
                (813) 672-8022</a>
            </p>
          </Box>
        </Grid>
    );
  }

  return (
      <div className={classes.root}>
        <Button disableElevation={true} aria-describedby={id}
                variant="contained"
                color="primary" onClick={handleClick}>
          <strong>Contact&nbsp;</strong> Us
        </Button>
        <Popover
            className={classes.agentPopupContent}
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
        >
          <Box p={2} bgcolor={`success.main`}>
            <Grid container spacing={1}>
              {personal_agent && pers_agent_img}
              {pers_agent_descr}
            </Grid>
          </Box>
          <Box p={2}>
            <WebformEmbed frameMinHeight={`600px`} key={`iframe_webform_19`}
                          name={`contact_us`}
                          webformid={19} html_id={`contact_us`}></WebformEmbed>
          </Box>
        </Popover>
      </div>
  );
};
