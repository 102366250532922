var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"wyXIDlZGr0z9ygDxisX8H"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// import * as SentryBrowser from "@sentry/browser";
import {Replay} from "@sentry/replay";
import { CaptureConsole } from "@sentry/integrations";
import * as Sentry from "@sentry/nextjs";

const env_lbl = (typeof window != "undefined" && !window?.location.origin?.includes("eatonrealty.com")) ? 'development' : (process.env.NODE_ENV || 'preview');

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN || '';
const SENTRY_AUTH_TOKEN = process.env.SENTRY_AUTH_TOKEN || '';

Sentry.init({
  dsn: SENTRY_DSN || 'https://c69b006791754a8eb24756da951027eb@o1339468.ingest.sentry.io/6617081',
  authToken: SENTRY_AUTH_TOKEN,
  debug: false, // (env_lbl !== "production") ? true : false,
  // Capture Replay for 0% of all sessions,
  // plus for 10% of sessions with an error
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0.1,
  // Set tracesSampleRate to 1.0 to capture 100% of transactions for tracing.
  // We recommend adjusting this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.02,
  tracesSampler: ({ name, attributes, parentSampled }) => {
    const re = new RegExp(/getStaticProps|sitemap|acsb/, 'gi');

    // Do not sample health checks ever
    if (env_lbl !== "production" || name.includes("/api/") || re.test(name)) {
      // Drop this transaction, by setting its sample rate to 0%
      return 0;
    }

    // Continue trace decision, if there is any parentSampled information
    // if (typeof parentSampled === "boolean") {
    //   return parentSampled;
    // }

    // Else, use default sample rate (replacing tracesSampleRate)
    return 0.02;
  },
  environment: env_lbl,
  // ignoreTransactions: [],
  ignoreErrors: ["Request has been terminated", "upstream image response failed", "find variable", "acsb", "this.Eg.close"],
  integrations: [
    // new Replay({
    //   // Additional Replay configuration goes in here, for example:
    //   // maskAllText: true,
    //   blockAllMedia: true,
    // }),
    new CaptureConsole({
      // array of methods that should be captured
      // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
      levels: ['error']
    })
  ],
});
